import { client } from '../sanityClient';

export const fetchCardCount = async () => {
    const query = `count(*[_type == "card"])`;

    try {
        const total = await client.fetch(query);
        return total;
    } catch (err) {
        console.error('Error fetching card count:', err);
        return [];
    }
};