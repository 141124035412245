import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCircleInfo, faShare, faFire } from '@fortawesome/free-solid-svg-icons';
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons';

const Icon = ({ name, size, color }) => {
  const icons = {
    envelope: faEnvelope,
    info: faCircleInfo,
    share: faShare,
    instagram: faSquareInstagram,
    fire: faFire
  };

  return <FontAwesomeIcon icon={icons[name]} style={{ fontSize: size, color }} />;
};

export default Icon;