import { client } from '../sanityClient';

export async function fetchBlogPosts() {
  const query = `*[_type == "blogPost"] | order(publishedAt desc) {
      title,
      slug,
      body,
      publishedAt,
      author,
      "imageUrl": image.asset->url
    }`;
  try {
    const blogPosts = await client.fetch(query);
    return blogPosts;
  } catch (err) {
    console.error('Error fetching blog posts:', err);
    return [];
  }
}