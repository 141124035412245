import { client } from '../sanityClient';

export const fetchCard = async (id) => {
  const query = `*[_type == "card" && _id == "${id}"]{
     _id,
    title,
    description,
    ctaButtonText,
    category,
    probability,
    season,
    place,
    externalLink,
    internalLink->{
    title,
    slug
    },
    cardImage {
    asset->{
        _id,
        url
    }
    },
    backgroundImage {
    asset->{
        _id,
        url
    }
    },
    publishedAt
  }[0]`; // Indexed at [0] since result is an array containing a single object.

  try {
    const card = await client.fetch(query);
    return card;
  } catch (err) {
    console.error('Error fetching cards:', err);
    return [];
  }
};