import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import './App.css';

import About from './pages/About';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';

import Layout from './components/Layout';
import Card from "./components/Card"
import Header from './components/Header';
import Snackbar from '@mui/material/Snackbar';
import Button from './components/Button'
import { fetchCard } from './queries/fetchCard';
import { fetchCardCount } from './queries/fetchCardCount';
import { fetchRandomCard } from './queries/fetchRandomCard';


function CardShuffler() {

  const navigate = useNavigate();
  const location = useLocation();

  // Get the current card ID from the query string
  const queryParams = new URLSearchParams(location.search);
  const queryCardId = queryParams.get('kort');

  const [card, setCard] = useState([])
  const [totalCardCount, setTotalCardCount] = useState(null);
  const [arrivedWithCardIdInURL, setArrivedWithCardIdInURL] = useState(false);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  // ARRIVING TO PAGE

  useEffect(() => {

    if (queryCardId) {
      //console.log("Arrived to page WITH card id in URL")
      fetchCard(queryCardId).then(setCard);
      setArrivedWithCardIdInURL(true)
    }

    fetchCardCount().then(setTotalCardCount);
  }, []);

  useEffect(() => {
    if (totalCardCount && !arrivedWithCardIdInURL) {
      fetchRandomCard(totalCardCount).then(setCard);
      //console.log("Arrived to page WITHOUT card id in URL")
    }
  }, [totalCardCount])

  // SWITCHING CARDS 

  useEffect(() => {
    if (card && card._id && queryCardId !== card._id) {
      navigate(`?kort=${card._id}`, { replace: true });
    }
  }, [card]);

  const nextCard = () => {
    if (totalCardCount !== null && card) {
      fetchRandomCard(totalCardCount, card._id).then(setCard);
    }
  };

  const previousCard = () => {
    console.log("todo");
  };

  return (
    <div className="App">
      <div>
        <div className='cardSection'>
          {card && <Card card={card} />}
        </div>

      </div>
      <div className='switchCardsButtons'>
        <Button label="Förra kortet" onClick={previousCard} disabled={true} square />
        <Button label="Nästa kort" onClick={nextCard} square />
      </div>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </div>
  );
}


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<CardShuffler />} />
        <Route path="/blogg" element={<Layout><Blog /></Layout>} />
        <Route
          path="/blogg/:slug"
          element={<Layout><BlogPost /></Layout>} />
        <Route path="/om-oss" element={<Layout><About /></Layout>} />
      </Routes>
    </Router>
  );
}

export default App;