import { client } from '../sanityClient';


export const fetchRandomCard = async (totalCards, currentCardId) => {

  try {
        const randomOffset = Math.floor(Math.random() * totalCards - 1);
        const query = `*[_type == "card" && _id != "${currentCardId}"]{
            _id,
            title,
            description,
            ctaButtonText,
            category,
            probability,
            season,
            place,
            externalLink,
            internalLink->{
            title,
            slug
            },
            cardImage {
            asset->{
                _id,
                url
            }
            },
            backgroundImage {
            asset->{
                _id,
                url
            }
            },
            publishedAt
        } [${randomOffset}]`;

        const randomCard = await client.fetch(query);
        return randomCard;
    } catch (err) {
        console.error('Error fetching cards:', err);
        return [];
    }
};