import { Link } from 'react-router-dom';
import './Header.css';
import Icon from './Icon'

const Header = () => {

    const handleSharePress = () => {
        //setSnackBarMessage("✅ Länk till sidan kopierad.")
        //setShowSnackbar(true)
        console.log("Share press")
    }

    const handleContactPress = () => {
        //setSnackBarMessage("✅ E-post kopierad.")
        //setShowSnackbar(true)
        console.log("Contact press")
    }


    return (
        <div className="header-container">
            <div className="left-icons">

                <Link to="/om-oss">
                    <Icon name="info" size={32} color="#05580D" />
                </Link>

                <Icon name="share" size={32} color="#05580D" />

            </div>
            <div className='middle'>
                <Link to="/">
                    <img src={require('../images/lettuce_v1.png')} className="lettuce-logo" alt='logo' />
                </Link>
            </div>
            <div className='right'>
                <Link to="/blogg">
                    <Icon name="fire" size={32} color="#05580D" />
                </Link>
                <a href='https://www.instagram.com/lettuce.nu/' target="_blank">
                    <Icon name="instagram" size={40} color="#05580D" />
                </a>
            </div>
        </div>
    )
};

export default Header;
