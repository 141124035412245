import React, { useState } from 'react';
import './Button.css';

const Button = ({
  label,
  onClick,
  style,
  className,
  disabled = false,
  square = false,
  secondary = false,
  growWidth = false
}) => {
  // States for hover and active (press) states
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  // Combine default styles with any custom styles passed via props
  const combinedStyles = {
    ...style,
    opacity: disabled ? 0.5 : 1,
    borderRadius: square ? "8px" : "40px",
    width: growWidth ? "100%" : ""
  };

  const colorScheme = secondary ? "secondaryCTAbutton" : "primaryCTAbutton";

  return (
    <button
      onClick={!disabled ? onClick : null} // Prevent onClick if disabled
      onMouseEnter={() => setIsHovered(true)} // Trigger hover state
      onMouseLeave={() => setIsHovered(false)} // Reset hover state
      onMouseDown={() => setIsPressed(true)} // Trigger press state
      onMouseUp={() => setIsPressed(false)} // Reset press state
      className={`${className} ${colorScheme} ${isHovered ? 'hover' : ''} ${isPressed ? 'pressed' : ''} ${disabled ? 'disabled' : ''}`}
      style={combinedStyles}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;