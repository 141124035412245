import React from 'react';
import './About.css';
import Icon from '../components/Icon';

const About = () => {
  return (
    <div>
      <h1>Om oss</h1>
      <p>Lettuce är under konstruktion. Följ oss på  <a href='https://www.instagram.com/lettuce.nu/' target="_blank">instagram</a> och häng med på resan!</p>
      <div className='email'>
      <Icon name="envelope" size={20} color="#05580D" />
      <span>hej@lettuce.nu</span>
      </div>
    </div>
  );
};

export default About;