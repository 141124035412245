import React, { useEffect, useState } from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import { fetchBlogPosts } from '../queries/fetchBlogPosts';

const Blog = () => {

  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    fetchBlogPosts().then(setBlogPosts);
  }, []);

  return (
    <div className="blog-wrapper">
      <h1>Blogg</h1>
      <div className="blogposts-section">
        {blogPosts.map((post) => (
          <Link key={post.slug.current} to={`/blogg/${post.slug.current}`} className="blogpost-card">
            <h2>{post.title}</h2>
            <p>{post.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blog;