import React, { useEffect, useState } from 'react';
import './BlogPost.css';
import { useParams } from 'react-router-dom';
import { client } from '../sanityClient';

const BlogPost = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      const query = `*[_type == "blogPost" && slug.current == $slug][0]{
        title,
        body,
        mainImage {
          asset-> {
            url
          }
        },
        publishedAt
      }`;
      const data = await client.fetch(query, { slug });
      setBlogPost(data);
    };

    fetchBlogPost();
  }, [slug]);

  if (!blogPost) return <p>Loading...</p>;

  return (
    <div className="blogpost-wrapper">
      <div className='blogpost-header'>
        <h1>{blogPost.title}</h1>
        <p>Publicerad {new Date(blogPost.publishedAt).toLocaleDateString()}</p>
      </div>
      <div className="hero-image">
        {blogPost.mainImage && <img src={blogPost.mainImage.asset.url} alt={blogPost.title} />}
      </div>
      <div className="main-text-container">
        {blogPost.body && blogPost.body.map((block) => block.children[0].text)}
      </div>



    </div>
  );
};

export default BlogPost;
