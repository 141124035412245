import './Card.css';
import { PortableText } from '@portabletext/react'
import Button from './Button'

const Card = ({ card }) => {

    const backgroundColors = ['#F9ECFC', '#E3FCEC', '#ECF9FC', '#FCF9EC', '#FCECEC'];

    const pastelColors = [
        '#F9ECFC', // Soft Pink
        '#E3FCEC', // Light Green
        '#ECF9FC', // Light Blue
        '#FCECEC', // Pale Coral
        '#E5FCF1', // Minty Aqua
        '#E6F7FF', // Powder Blue
    ];
    
    const darkerColors = [
        '#D8BFD8', // Thistle
        '#A2D2A2', // Muted Green
        '#90CAF9', // Muted Blue
        '#FFD54F', // Amber
        '#FF8A80', // Soft Red
        '#CDA4DE', // Medium Lavender
        '#81C784', // Forest Mint
        '#4FC3F7', // Sky Blue
        '#FFA726', // Burnt Orange
        '#E57373', // Coral Rose
    ];

    const randomBackground = () => {
        const randomIndex = Math.floor(Math.random() * backgroundColors.length); // Get a random index
        return backgroundColors[randomIndex]; // Return a color from the array
    };
    
    const randomGradientBackground = () => {
        const getRandomColor = (colors) => colors[Math.floor(Math.random() * colors.length)];
    
        const color1 = getRandomColor(pastelColors); // Pick from pastel colors
        const color2 = getRandomColor(darkerColors); // Pick from darker colors
    
        return `linear-gradient(135deg, ${color1} 10%, ${color2} 100%)`;
 
    };

    const handleCTAButtonClick = (card) => {

        try {
            if (card.internalLink && card.internalLink.slug.current && window.location.origin) {

                const fullUrl = `${window.location.origin}/blogg/${card.internalLink.slug.current}`;
                window.open(fullUrl, '_blank', 'noopener,noreferrer');
            }

            if (card.externalLink) {
                window.open(card.externalLink, '_blank', 'noopener,noreferrer');
            }
        } catch (e) {
            console.error(e)
        }
    }

    if (!card) return null

    return (
        <div className='card-container' style={{ background: randomGradientBackground() }} >
            <div className='text-content'>
                <h2>{card.title}</h2>
                {card.description && (
                    <div className="description">
                        <PortableText value={card.description} />
                    </div>
                )}

            </div>
            <div className="card-action-bar">
                {
                    (card.internalLink || card.externalLink) &&
                    <Button
                        label={card.ctaButtonText ? card.ctaButtonText : "Låt oss!"}
                        onClick={() => handleCTAButtonClick(card)} 
                        secondary 
                        growWidth />
                }
            </div>

        </div>
    )
};

export default Card;

